import ApplicationAbility from './application';

export default class CapitalDepositAbility extends ApplicationAbility {
  get canIncrease() {
    let isFrenchCompany = this.organization.legalCountry === 'FR';
    return (
      isFrenchCompany &&
      !this.organization.underRegistration &&
      this.permissions.capital_deposits.access
    );
  }

  get canNavigate() {
    return this.canIncrease;
  }
}
