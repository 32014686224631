import BillerAdapter from 'qonto/adapters/base/biller';
import { billerV3Namespace } from 'qonto/constants/hosts';

export default class OrganizationSubscriptionAdapter extends BillerAdapter {
  namespace = billerV3Namespace;
  pathForType() {
    return 'subscriptions';
  }

  handleResponse(status, headers, payload) {
    if (status === 422 && 'estimated_price' in payload) {
      return { payload, status };
    }

    return super.handleResponse(...arguments);
  }
}
