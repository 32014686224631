import BillerAdapter from 'qonto/adapters/base/biller';
import { billerV2Namespace } from 'qonto/constants/hosts';

export default class PricePlanAdapter extends BillerAdapter {
  namespace = billerV2Namespace;
  shouldReloadRecord() {
    return false;
  }

  shouldBackgroundReloadRecord() {
    return false;
  }

  shouldReloadAll() {
    return true;
  }
}
