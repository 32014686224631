import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { documentItemsNamespace } from 'qonto/constants/hosts';

export default class DocumentItemsAdapter extends JSONAPIAdapter {
  namespace = documentItemsNamespace;

  pathForType() {
    return 'receivable_invoices/document_items';
  }
}
