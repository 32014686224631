import { underscore } from '@ember/string';
import { waitFor } from '@ember/test-waiters';

import { adapterAction, apiAction } from '@mainmatter/ember-api-actions';

import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { supplierInvoiceNamespace } from 'qonto/constants/hosts';

export default class SupplierInvoiceAdapter extends JSONAPIAdapter {
  namespace = supplierInvoiceNamespace;

  @waitFor
  async updateRecord(store, type, snapshot) {
    let data = { supplier_invoice: this.serialize(snapshot, { includeId: true }) };

    if (snapshot.adapterOptions?.partial) {
      let updates = Object.keys(snapshot.changedAttributes()).reduce((record, key) => {
        let underscored = underscore(key);
        record[underscored] = data.supplier_invoice[underscored];
        return record;
      }, {});

      data = { supplier_invoice: { ...updates, id: snapshot.id } };
    }

    return await apiAction(snapshot.record, { method: 'PATCH', data });
  }

  @waitFor
  async export({ dateFrom, dateTo, format, groupByStatuses }) {
    await adapterAction(this, 'supplier-invoices', {
      method: 'POST',
      path: 'export',
      data: {
        date_from: dateFrom,
        date_to: dateTo,
        format,
        group_by_statuses: groupByStatuses,
      },
    });
  }
}
