import ApplicationAbility from './application';

export default class BankSwitchAbility extends ApplicationAbility {
  get canView() {
    return (
      this.organization.hasBankSwitchFeature &&
      this.permissions.bankSwitch.access &&
      !this.organization.underRegistration
    );
  }

  get canNavigate() {
    return this.canView;
  }
}
