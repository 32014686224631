import { bool } from 'macro-decorators';

import ApplicationAbility from './application';

export default class TransferAbility extends ApplicationAbility {
  @bool('pricePlanFeatures.multiTransfers') canCreateBulk;

  get canRead() {
    return this.permissions.external_transfers.read;
  }

  get canNavigate() {
    return this.canRead;
  }

  get canUpdate() {
    return this.permissions.external_transfers.update;
  }

  get canCancel() {
    return this.permissions.external_transfers.cancel;
  }

  get canCreate() {
    return this.permissions.external_transfers.create;
  }

  get canCreateInternal() {
    return this.permissions.internal_transfers.create;
  }

  get canPayByInvoice() {
    let { supplierInvoices } = this.permissions;
    return supplierInvoices.update && supplierInvoices.read && supplierInvoices.upload;
  }

  get canGenerateProof() {
    return this.permissions.external_transfers.generateProof;
  }
}
