import dayjs from 'dayjs';
import { reads } from 'macro-decorators';

import {
  ENABLED_DE_NACE_CODES,
  getEnabledLegalCountries,
  REGISTRATION_DATE_THRESHOLD_MONTHS,
} from 'qonto/constants/insurance-hub';

import ApplicationAbility from './application';

export default class InsuranceAbility extends ApplicationAbility {
  @reads('organizationManager.organization') organization;

  get canRead() {
    return (
      this.permissions.insurance_contracts.read &&
      getEnabledLegalCountries().includes(this.organizationManager.organization.legalCountry)
    );
  }

  get canNavigate() {
    return this.canRead && this.isGermanOrganizationValid();
  }

  isGermanOrganizationValid() {
    if (this.organization.isGerman) {
      return (
        dayjs(this.organization.legalRegistrationDate).isAfter(
          dayjs().subtract(REGISTRATION_DATE_THRESHOLD_MONTHS, 'month')
        ) && ENABLED_DE_NACE_CODES.includes(this.organization.legalSector)
      );
    }

    return true;
  }
}
