import ApplicationAbility from './application';

export default class ApprovalWorkflowAbility extends ApplicationAbility {
  get canSeeState() {
    return (
      Boolean(this.pricePlanFeatures.approvalWorkflows) &&
      this.featuresManager.isEnabled('approvalWorkflows')
    );
  }

  get canRead() {
    return (
      Boolean(this.pricePlanFeatures.approvalWorkflows) &&
      this.featuresManager.isEnabled('approvalWorkflows') &&
      this.permissions.approval_workflows.read
    );
  }

  get canCreate() {
    return (
      Boolean(this.pricePlanFeatures.approvalWorkflows) &&
      this.featuresManager.isEnabled('approvalWorkflows') &&
      this.hasCreatePermission
    );
  }

  get canUpdate() {
    return (
      Boolean(this.pricePlanFeatures.approvalWorkflows) &&
      this.featuresManager.isEnabled('approvalWorkflows') &&
      this.permissions.approval_workflows.edit
    );
  }

  get canUpsell() {
    return !this.pricePlanFeatures.approvalWorkflows && this.hasCreatePermission;
  }

  get hasCreatePermission() {
    return this.permissions.approval_workflows.create;
  }
}
