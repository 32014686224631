import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import ApplicationAbility from './application';

export default class TaskAbility extends ApplicationAbility {
  @service abilities;

  get canNavigate() {
    if (variation('feature--boolean-enable-reimbursements')) {
      let canAccessTask = Boolean(
        (this.abilities.can('review transfer request') ||
          this.abilities.can('review card request')) &&
          this.pricePlanFeatures.tasks
      );

      return this.abilities.can('read ebics request') || canAccessTask;
    } else {
      let canAccessTask = Boolean(
        (this.abilities.can('review transfer request') ||
          this.abilities.can('review card request')) &&
          this.pricePlanFeatures.connectIntegrationLevelSilver
      );

      return this.abilities.can('read ebics request') || canAccessTask;
    }
  }
}
