import ApplicationAbility from './application';

export default class Check extends ApplicationAbility {
  get isCheckEnabled() {
    let { isKYBAccepted, organization } = this;
    return isKYBAccepted && !organization.underRegistration;
  }

  get canView() {
    let { isCheckEnabled, permissions, featuresManager } = this;

    return isCheckEnabled && permissions.checks.read && featuresManager.isEnabled('checksList');
  }

  get canNavigate() {
    return this.canView;
  }

  get canCreate() {
    let { permissions, featuresManager } = this;
    let isCheckCreationEnabled =
      // TODO : remove this flag check when switcth to checksCreationWithTessi flag is complete
      featuresManager.isEnabled('checksCreationWithMandate') ||
      featuresManager.isEnabled('checksCreationWithTessi');
    return permissions.checks.create && isCheckCreationEnabled;
  }
}
