import { bool } from 'macro-decorators';

import ApplicationAbility from './application';

export default class SupplierInvoiceAbility extends ApplicationAbility {
  @bool('pricePlanFeatures.supplierInvoices') canAccess;

  get canView() {
    return this.permissions.supplierInvoices.read;
  }

  get canUpdate() {
    return this.permissions.supplierInvoices.update;
  }

  /**
   * @deprecated
   * We used to have a separate `delete` permission but it has been removed
   */
  get canDelete() {
    return this.canUpdate;
  }

  get canUpload() {
    return this.permissions.supplierInvoices.upload;
  }

  get canRequestTransfer() {
    return this.permissions.requestTransfers.create;
  }

  get canNavigate() {
    return !this.organization.isDeactivated && this.canView;
  }

  get canUse() {
    return this.canAccess;
  }

  get canReview() {
    return this.canUse && this.canView && this.canUpdate;
  }

  get canExport() {
    return this.canAccess && this.canView;
  }
}
